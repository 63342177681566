<template>
    <div class="ResponsiveContentBoard">
        <label class="textLarge title"> 
            Em contrução
        </label>
    </div>
</template>
<script> 
    import router from '@/router/index'
    export default {
        data() {
            return {
            }
        },
    }  
</script>

<style scoped>
.title{
    text-align: center;
}


</style>